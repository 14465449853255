import { graphql } from 'gatsby';
import React from 'react';
import { withPreview } from 'gatsby-source-prismic';
import PropTypes from 'prop-types';
import { Shell } from 'layout';
import { FeatureBlockListing, MastheadCarousel } from 'organisms';
import { renderComponents } from 'utility';

import 'scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

function IndexPage({
    data,
    location: {
        pathname,
    },
}) {
    const doc = data?.prismicHomePage?.data;
    const siteData = data?.prismicSite;
    const featureBlocks = doc?.feature_blocks;
    const mastheadCarousel = doc?.carousel;
    const pageDescription = doc?.page_meta_description?.text;
    const pageSocialImage = doc?.page_social_share_image?.url;
    const pageTitle = doc?.page_meta_title?.text;
    const urgentMessageDescription = doc?.urgent_message_description?.raw;
    const urgentMessageShow = doc?.urgent_message_show_message;
    const urgentMessageTitle = doc?.urgent_message_title?.text;
    const components = doc?.body;

    return (
        <Shell
            pathname={pathname}
            seoDescription={pageDescription}
            seoTitle={pageTitle}
            seoImage={pageSocialImage}
            siteData={siteData}
            urgentMessageDescription={urgentMessageDescription}
            urgentMessageShow={urgentMessageShow}
            urgentMessageTitle={urgentMessageTitle}
        >
            <main className="l-grid__main" id="main-content">
                {mastheadCarousel && (
                    <MastheadCarousel
                        image={doc?.background_image}
                        listing={mastheadCarousel}
                        video={doc?.background_video}
                    />
                )}
                {featureBlocks && (
                    <FeatureBlockListing
                        listing={featureBlocks}
                    />
                )}
                {components?.map(component => (
                    renderComponents(component, component?.slice_type)
                ))}
                <img src="https://d21y75miwcfqoq.cloudfront.net/59298ab7" />
            </main>
        </Shell>
    );
}

export const query = graphql`
    query($id: String!) {
        prismicSite {
            ...FooterQuery
        }
        prismicSite {
            ...HeaderQuery
        }
        prismicSite {
            ...NavQuery
        }
        prismicHomePage(id: { eq: $id }) {
            data {
                body {
                    ... on PrismicHomePageBodyAccordion {
                        items {
                            content {
                                raw
                            }
                            title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyBlockquote {
                        primary {
                            author_link {
                                link_type
                                type
                                uid
                                url
                            }
                            author_name {
                                text
                            }
                            background_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            quote {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyContentImage1 {
                        primary {
                            button_text {
                                text
                            }
                            button_url {
                                link_type
                                type
                                uid
                                url
                            }
                            content {
                                raw
                            }
                            table_colour
                            image_1 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_2 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_3 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_4 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_5 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            reverse_layout
                            image_size
                            title {
                                text
                            }
                            title_icon {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyChart {
                        items {
                            chart_label {
                                text
                            }
                            chart_percentage
                        }
                        primary {
                            chart_colour
                            chart_content {
                                raw
                            }
                            chart_graph_label {
                                text
                            }
                            chart_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyContent {
                        primary {
                            content_content {
                                raw
                            }
                            table_colour
                            content_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyDownload {
                        primary {
                            download_button_link {
                                link_type
                                size
                                type
                                uid
                                url
                            }
                            download_button_text {
                                text
                            }
                            download_file_name {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyImage {
                        primary {
                            image_caption {
                                text
                            }
                            image_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyLogoListing {
                        items {
                            logo_listing_link {
                                link_type
                                type
                                uid
                                url
                            }
                            logo_listing_logo {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyQuickLinks {
                        items {
                            quick_links_button_text {
                                text
                            }
                            quick_links_button_textbutton_background_colour
                            quick_links_button_textbutton_url {
                                link_type
                                type
                                uid
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyTeamListing {
                        items {
                            team_listing_content {
                                raw
                            }
                            team_listing_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            team_listing_job_title {
                                text
                            }
                            team_listing_name {
                                text
                            }
                        }
                        primary {
                            team_listing_grid_layout
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyValueListing {
                        items {
                            value_listing_value_content {
                                raw
                            }
                            value_listing_value_title {
                                text
                            }
                        }
                        primary {
                            value_listing_image_1 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            value_listing_image_2 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            value_listing_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyVideo {
                        primary {
                            video_caption {
                                text
                            }
                            video_video {
                                html
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyLoanForm {
                        primary {
                            loan_form_call_time_field_label {
                                text
                            }
                            loan_form_content {
                                text
                            }
                            loan_form_email_field_label {
                                text
                            }
                            loan_form_farm_type_field_label {
                                text
                            }
                            loan_form_farm_type_field_options {
                                text
                            }
                            loan_form_footer_content {
                                raw
                            }
                            loan_form_land_farmed_field_label {
                                text
                            }
                            loan_form_land_farmed_field_options {
                                text
                            }
                            loan_form_last_name_field_label {
                                text
                            }
                            loan_form_loan_amount_field_label {
                                text
                            }
                            loan_form_loan_reason_field_label {
                                text
                            }
                            loan_form_loan_reason_field_options {
                                text
                            }
                            loan_form_loan_value_field_label {
                                text
                            }
                            loan_form_loan_value_field_options {
                                text
                            }
                            loan_form_message_field_label {
                                text
                            }
                            loan_form_name_field_label {
                                text
                            }
                            loan_form_phone_field_label {
                                text
                            }
                            loan_form_postcode_field_label {
                                text
                            }
                            loan_form_submit_button_text {
                                text
                            }
                            loan_form_subscribe_email_checkbox_label {
                                text
                            }
                            loan_form_subscribe_none_checkbox_label {
                                text
                            }
                            loan_form_subscribe_post_checkbox_label {
                                text
                            }
                            loan_form_subscribe_sms_checkbox_label {
                                text
                            }
                            loan_form_subscribe_tel_checkbox_label {
                                text
                            }
                            loan_form_term_length_field_label {
                                text
                            }
                            loan_form_term_length_field_options {
                                text
                            }
                            loan_form_title {
                                text
                            }
                            loan_form_product {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyContactForm {
                        primary {
                            contact_form_call_time_field_label {
                                text
                            }
                            contact_form_content {
                                raw
                            }
                            contact_form_email_field_label {
                                text
                            }
                            contact_form_farm_size_field_label {
                                text
                            }
                            contact_form_farm_type_field_label {
                                text
                            }
                            contact_form_farm_type_field_options {
                                text
                            }
                            contact_form_footer_content {
                                raw
                            }
                            contact_form_input_supplier_field_label {
                                text
                            }
                            contact_form_land_farmed_field_label {
                                text
                            }
                            contact_form_land_farmed_field_options {
                                text
                            }
                            contact_form_last_name_field_label {
                                text
                            }
                            contact_form_loan_value_field_label {
                                text
                            }
                            contact_form_loan_value_field_options {
                                text
                            }
                            contact_form_message_field_label {
                                text
                            }
                            contact_form_name_field_label {
                                text
                            }
                            contact_form_phone_field_label {
                                text
                            }
                            contact_form_postcode_field_label {
                                text
                            }
                            contact_form_submit_button_text {
                                text
                            }
                            contact_form_subscribe_email_checkbox_label {
                                text
                            }
                            contact_form_subscribe_none_checkbox_label {
                                text
                            }
                            contact_form_subscribe_post_checkbox_label {
                                text
                            }
                            contact_form_subscribe_sms_checkbox_label {
                                text
                            }
                            contact_form_subscribe_tel_checkbox_label {
                                text
                            }
                            contact_form_title {
                                text
                            }
                            contact_form_product {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyCarbonCalculator {
                        primary {
                            cc_content {
                                raw
                            }
                            cc_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            cc_panel_1_carbon_label {
                                text
                            }
                            cc_panel_1_deposit_label {
                                text
                            }
                            cc_panel_1_interest_label {
                                text
                            }
                            cc_panel_1_tree_label {
                                text
                            }
                            cc_panel_2_carbon_label {
                                text
                            }
                            cc_panel_2_deposit_label {
                                text
                            }
                            cc_panel_2_interest_label {
                                text
                            }
                            cc_panel_2_tree_label {
                                text
                            }
                            cc_panel_icon {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            cc_tab_1_name {
                                text
                            }
                            cc_tab_2_name {
                                text
                            }
                            cc_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyNoticeMessage {
                        primary {
                            notice_message_description {
                                raw
                            }
                            notice_message_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomePageBodyFeefoReviewCards {
                        primary {
                            feefo_reviews_title {
                                text
                            }
                            feefo_reviews_icon {
                                alt
                                url
                            }
                            more_reviews_button_text {
                                text
                            }
                            more_reviews_button_link {
                                url
                            }
                        }
                        items {
                            review_title {
                                text
                            }
                            review_text {
                                text
                            }
                            review_stars {
                                url
                                alt
                            }
                            review_product {
                                text
                            }
                        }
                        slice_type
                    }
                }
                carousel {
                    background_image {
                        alt
                        dimensions {
                            height
                            width
                        }
                        url
                    }
                    background_video {
                        url
                    }
                    cta_button_text {
                        text
                    }
                    cta_button_url {
                        link_type
                        type
                        uid
                        url
                    }
                    cta_colour
                    cta_title {
                        raw
                    }
                    description {
                        text
                    }
                    is_feefo_review
                    review_text {
                        raw
                    }
                    review_title {
                        raw
                    }
                    strapline {
                        text
                    }
                    title {
                        text
                    }
                }
                feature_blocks {
                    description {
                        text
                    }
                    icon {
                        alt
                        dimensions {
                            height
                            width
                        }
                        url
                    }
                    link {
                        link_type
                        type
                        uid
                        url
                    }
                    link_text {
                        text
                    }
                    title {
                        text
                    }
                }
                page_meta_description {
                    text
                }
                page_meta_title {
                    text
                }
                page_social_share_image {
                    url
                }
                urgent_message_description {
                    raw
                }
                urgent_message_show_message
                urgent_message_title {
                    text
                }
            }
        }
    }
`;

IndexPage.propTypes = propTypes;
IndexPage.defaultProps = defaultProps;

export default withPreview(IndexPage);
